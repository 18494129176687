exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-affordability-suite-jsx": () => import("./../../../src/pages/affordability-suite.jsx" /* webpackChunkName: "component---src-pages-affordability-suite-jsx" */),
  "component---src-pages-api-banking-jsx": () => import("./../../../src/pages/api-banking.jsx" /* webpackChunkName: "component---src-pages-api-banking-jsx" */),
  "component---src-pages-auto-collect-jsx": () => import("./../../../src/pages/auto-collect.jsx" /* webpackChunkName: "component---src-pages-auto-collect-jsx" */),
  "component---src-pages-bank-account-verification-jsx": () => import("./../../../src/pages/bank-account-verification.jsx" /* webpackChunkName: "component---src-pages-bank-account-verification-jsx" */),
  "component---src-pages-card-tokenization-jsx": () => import("./../../../src/pages/card-tokenization.jsx" /* webpackChunkName: "component---src-pages-card-tokenization-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-case-study-jsx": () => import("./../../../src/pages/case-study.jsx" /* webpackChunkName: "component---src-pages-case-study-jsx" */),
  "component---src-pages-cashgram-jsx": () => import("./../../../src/pages/cashgram.jsx" /* webpackChunkName: "component---src-pages-cashgram-jsx" */),
  "component---src-pages-demo-page-jsx": () => import("./../../../src/pages/demo-page.jsx" /* webpackChunkName: "component---src-pages-demo-page-jsx" */),
  "component---src-pages-e-commerce-jsx": () => import("./../../../src/pages/e-commerce.jsx" /* webpackChunkName: "component---src-pages-e-commerce-jsx" */),
  "component---src-pages-education-jsx": () => import("./../../../src/pages/education.jsx" /* webpackChunkName: "component---src-pages-education-jsx" */),
  "component---src-pages-enach-jsx": () => import("./../../../src/pages/enach.jsx" /* webpackChunkName: "component---src-pages-enach-jsx" */),
  "component---src-pages-global-payments-jsx": () => import("./../../../src/pages/global-payments.jsx" /* webpackChunkName: "component---src-pages-global-payments-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-instant-settlements-jsx": () => import("./../../../src/pages/instant-settlements.jsx" /* webpackChunkName: "component---src-pages-instant-settlements-jsx" */),
  "component---src-pages-merchant-support-jsx": () => import("./../../../src/pages/merchant-support.jsx" /* webpackChunkName: "component---src-pages-merchant-support-jsx" */),
  "component---src-pages-nbfc-jsx": () => import("./../../../src/pages/nbfc.jsx" /* webpackChunkName: "component---src-pages-nbfc-jsx" */),
  "component---src-pages-partner-integration-jsx": () => import("./../../../src/pages/partner-integration.jsx" /* webpackChunkName: "component---src-pages-partner-integration-jsx" */),
  "component---src-pages-partner-jsx": () => import("./../../../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-payment-gateway-jsx": () => import("./../../../src/pages/payment-gateway.jsx" /* webpackChunkName: "component---src-pages-payment-gateway-jsx" */),
  "component---src-pages-payment-links-jsx": () => import("./../../../src/pages/payment-links.jsx" /* webpackChunkName: "component---src-pages-payment-links-jsx" */),
  "component---src-pages-payouts-jsx": () => import("./../../../src/pages/payouts.jsx" /* webpackChunkName: "component---src-pages-payouts-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-pricing-new-jsx": () => import("./../../../src/pages/pricing-new.jsx" /* webpackChunkName: "component---src-pages-pricing-new-jsx" */),
  "component---src-pages-shopify-jsx": () => import("./../../../src/pages/shopify.jsx" /* webpackChunkName: "component---src-pages-shopify-jsx" */),
  "component---src-pages-split-settlement-jsx": () => import("./../../../src/pages/split-settlement.jsx" /* webpackChunkName: "component---src-pages-split-settlement-jsx" */),
  "component---src-pages-upi-autopay-jsx": () => import("./../../../src/pages/upi-autopay.jsx" /* webpackChunkName: "component---src-pages-upi-autopay-jsx" */),
  "component---src-pages-upi-payment-jsx": () => import("./../../../src/pages/upi-payment.jsx" /* webpackChunkName: "component---src-pages-upi-payment-jsx" */),
  "component---src-pages-verification-suite-jsx": () => import("./../../../src/pages/verification-suite.jsx" /* webpackChunkName: "component---src-pages-verification-suite-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */)
}

